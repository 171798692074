import React, { useEffect, useState } from "react";
import ParentCalendar from "../../../components/shared/SingleDayCalendar/ParentCalendar";
import DailySales from "../components/DailySales";
import DailyOperationalScore from "../components/DailyOperationalScore";
import DailyAverageRating from "../components/DailyAverageRating";
import { LocationIcon } from "../../../components/icons";

import CustomDropdownCheckbox from "../../../components/global-components/CustomDropdownChekbox";

import CustomerReviews from "../components/CustomerReviews";
import DailyTable from "../components/DailyTable";
import { useCallGetQuery, useCallPostMutation } from "../../../services/api";
import { useDispatch } from "react-redux";
import { setSelectedDatesdailyOp } from "../../../domain/DailyOperations/slice/DailyOperationSlice";
import { Atom } from "lucide-react";

const DailyOperations = () => {
  // Location related states
  const { data } = useCallGetQuery("weekly-operations/get-location");
  const [totalPage, setTotalPage] = useState(0);
  const [locationData, setLocationData] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [DailyDate, setDailyDate] = useState("");

  // Data states
  const [getDailySalesData, setGetDailySalesData] = useState([]);
  const [getDailyRatingData, setGetDailyRatingData] = useState([]);
  const [getDailyGraphData, setGetDailyGraphData] = useState([]);

  const [dailyOperationRefundsData, setDailyOperationRefundsData] = useState(
    []
  );
  const [dailyOperationReviewData, setDailyOperationReviewData] = useState([]);
  const [sortValues, setSortValues] = useState();
  const [sortValues2, setSortValues2] = useState();

  const [startDailyDate, setStartDailyDate] = useState();
  const [endDailyDate, setEndDailyDate] = useState();

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  // API hooks
  const [fetchDailySales] = useCallPostMutation();
  const [DailyRatingData] = useCallPostMutation();
  const [dailyOperationRefunds] = useCallPostMutation();
  const [DailyOperationReview] = useCallPostMutation();
  const [DailyGraphData] = useCallPostMutation();
  const dispatch = useDispatch();

  // Loading state

  const [DailySalesLoading, setDailySalesLoading] = useState(false);
  const [AverageDailyRating, setAverageDailyRating] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [LoadingTable, setLoadingTable] = useState(false);
  const [LoadingGraph, setLoadingGraph] = useState(false);

  // Second payload pass if isDatePickeisvisible  true
  const [secondPayload, setSecondPayload] = useState(false);

  // Fetch initial date and set it
  const { data: initialDate } = useCallGetQuery(
    "weekly-operations/get-all-daily-oprations-data",
    {
      skip: false,
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    if (initialDate?.status) {
      dispatch(setSelectedDatesdailyOp({ dates: initialDate?.data?.date }));
      setDailyDate(initialDate?.data?.date);
    }
  }, [initialDate, dispatch]);

  useEffect(() => {
    if (data?.status) {
      const location = data?.data?.map((item) => ({
        value: item.Name,
        label: item.Name,
      }));

      location.sort((a, b) => a.label.localeCompare(b.label));

      setLocationData(location);
      setSelectedLocations(location.map((item) => item.value));
    }
  }, [data]);

  useEffect(() => {
    setDailySalesLoading(true);

    const fetchData = async () => {
      if (DailyDate && selectedLocations.length > 0) {
        try {
          const payload = {
            startDate: secondPayload ? startDailyDate : DailyDate,
            ...(secondPayload && { endDate: endDailyDate }),
            locations:
              locationData.length === selectedLocations.length
                ? ["all"]
                : selectedLocations,
            ...(secondPayload && { rangeType: "custom" }),
          };

          const response = await fetchDailySales({
            url: "weekly-operations/get-daily-sales-data",
            body: payload,
          });

          setGetDailySalesData(response?.data);
          setDailySalesLoading(false);
        } catch (error) {
          console.error("Error fetching daily sales data:", error);
          setDailySalesLoading(false);
        }
      }
    };

    fetchData();
  }, [
    DailyDate,
    selectedLocations,
    fetchDailySales,
    secondPayload,
    startDailyDate,
    endDailyDate,
  ]);
  useEffect(() => {
    setAverageDailyRating(true);

    const fetchData = async () => {
      if (DailyDate && selectedLocations.length >= 0) {
        try {
          const payload = {
            startDate: secondPayload ? startDailyDate : DailyDate,
            ...(secondPayload && { endDate: endDailyDate }),
            locations:
              locationData.length === selectedLocations.length
                ? ["all"]
                : selectedLocations.length === 0
                ? []
                : selectedLocations,
            ...(secondPayload && { rangeType: "custom" }), // Add rangeType if secondPayload is true
          };

          const response = await DailyRatingData({
            url: "weekly-operations/get-daily-rating-data",
            body: payload,
          });

          setGetDailyRatingData(response?.data);
          setAverageDailyRating(false);
        } catch (error) {
          console.error("Error fetching daily rating data:", error);
          setAverageDailyRating(false);
        }
      }
    };

    fetchData();
  }, [
    DailyDate,
    selectedLocations,
    DailyRatingData,
    secondPayload,
    startDailyDate,
    endDailyDate,
  ]);

  useEffect(() => {
    setLoadingTable(true);

    const fetchData = async () => {
      if (DailyDate && selectedLocations.length > 0) {
        try {
          const payload = {
            startDate: secondPayload ? startDailyDate : DailyDate,
            ...(secondPayload && { endDate: endDailyDate }),
            deliveryApp: sortValues2,
            locations:
              locationData.length === selectedLocations.length
                ? ["all"]
                : selectedLocations,
            itemsPerPage,
            page: currentPage,
          };

          const response = await dailyOperationRefunds({
            url: "weekly-operations/get-daily-oprations-refund",
            body: payload,
          });

          setDailyOperationRefundsData(response?.data);
          setTotalPage(response?.data?.totalPage);
          setLoadingTable(false);
        } catch (error) {
          console.error("Error fetching daily operations refunds data:", error);
          setLoadingTable(false); // Stop loading in case of error
        }
      }
    };

    fetchData();
  }, [
    DailyDate,
    selectedLocations,
    sortValues2,
    currentPage,
    itemsPerPage,
    secondPayload,
    startDailyDate,
    endDailyDate,
  ]);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      if (DailyDate && selectedLocations.length > 0) {
        try {
          const payload = {
            startDate: secondPayload ? startDailyDate : DailyDate,
            ...(secondPayload && { endDate: endDailyDate }),
            locations:
              locationData.length === selectedLocations.length
                ? ["all"]
                : selectedLocations,
            sort: sortValues,
            page: 1,
          };

          const response = await DailyOperationReview({
            url: "weekly-operations/get-daily-oprations-review",
            body: payload,
          });

          setDailyOperationReviewData(response?.data);
          setLoading(false);
          console.log("DailyOperationReview", response);
        } catch (error) {
          console.error("Error fetching daily rating data:", error);
          setLoading(false); // Stop loading in case of error
        }
      }
    };

    fetchData();
  }, [
    DailyDate,
    selectedLocations,
    sortValues,
    DailyOperationReview,
    secondPayload,
    startDailyDate,
    endDailyDate,
  ]);

  useEffect(() => {
    setLoadingGraph(true);
    const fetchData = async () => {
      if (DailyDate && selectedLocations.length >= 0) {
        try {
          const payload = {
            startDate: DailyDate,
            locations:
              locationData.length === selectedLocations.length
                ? ["all"]
                : selectedLocations.length === 0
                ? []
                : selectedLocations,
          };

          const response = await DailyGraphData({
            url: "weekly-operations/get-daily-grabh-data",
            body: payload,
          });

          setGetDailyGraphData(response?.data?.data);
          setLoadingGraph(false);
        } catch (error) {
          console.error("Error fetching daily rating data:", error);
          setLoadingGraph(false);
        }
      }
    };

    fetchData();
  }, [DailyDate, selectedLocations, DailyGraphData]);

  const handleDateChange = (date, isDatePickerVisible) => {
    setSecondPayload(isDatePickerVisible);
    if (!isDatePickerVisible) {
      setDailyDate(date);
    } else {
      const dates = date.split(", ");

      if (dates.length === 2) {
        const [startDate, endDate] = dates;
        setStartDailyDate(startDate);
        setEndDailyDate(endDate);
      } else {
        console.log("Invalid date format");
      }
    }

    console.log("Date changed", isDatePickerVisible);
  };

  const handleSelectionChange = (selectedValues) => {
    setSelectedLocations(selectedValues);
  };

  // Pagination handlers
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handleSortChange = (newSortValues) => {
    setSortValues(newSortValues);
  };
  const handleSortChange2 = (newSortValues) => {
    setSortValues2(newSortValues);
  };

  return (
    <div className="">
      <div className="flex flex-col sm:flex-row items-center gap-4 w-full mb-4">
        <div className="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden rounded-full bg-gray-900">
          <span className="font-medium text-gray-600">
            <Atom color="#17E080" />
          </span>
        </div>

        <div className="flex flex-col flex-grow">
          <span className="text-lg text-primary-7 font-semibold">
            Daily Operations
          </span>
          <span className="text-sm font-medium text-primary-5">
            View and manage all your active and upcoming kitchen locations with
            ease
          </span>
        </div>
      </div>
      <div className="flex justify-between">
        <ParentCalendar onDateChange={handleDateChange} />
        <div className="flex items-center justify-center gap-2">
          <LocationIcon />
          <span className="text-primary-6 text-base font-[600]">Location</span>
          {locationData.length > 0 && (
            <CustomDropdownCheckbox
              dropdownType={"DailyOperationsLocation"}
              onSelectionChange={handleSelectionChange}
              options={locationData}
              defaultSelectAll={true}
              customBorderColor={"#A1AEBF"}
              customBorderRadius={2}
              customBorderWidth={2}
              menuHeight={200}
              menuWidth={160}
            />
          )}
        </div>
      </div>

      <div className="flex gap-4 mt-4">
        <div className="flex-1">
          <DailySales
            weeklyLoading={DailySalesLoading}
            getDailySalesData={getDailySalesData}
          />
        </div>
        <div className="flex-1">
          <DailyOperationalScore weeklyLoading={false} />
        </div>
        <div className="flex-1">
          <DailyAverageRating
            weeklyLoading={AverageDailyRating}
            getDailyRatingData={getDailyRatingData}
          />
        </div>
      </div>

      {/* <Chart data={getDailyGraphData.graph} weeklyLoading={LoadingGraph} /> */}

      <CustomerReviews
        dailyOperationReviewData={dailyOperationReviewData}
        onSortChange={handleSortChange}
        loading={Loading}
      />

      <DailyTable
        onSortChange={handleSortChange2}
        loading={LoadingTable}
        options={dailyOperationRefundsData}
        dailyOperationRefundsData={dailyOperationRefundsData?.refund}
        totalPages={totalPage}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        onPageChange={handlePageChange} // Updated to use the pagination handler
        onItemsPerPageChange={handleItemsPerPageChange} // Updated to use the items per page handler
      />
    </div>
  );
};

export default DailyOperations;
